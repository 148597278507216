<template>
    <div id="Weil">
        <!-- 八益·维宜尔产品理念 -->
        <Spot style="margin-top: 112px; margin-bottom: 50px" :SpotZhTitle="SpotZhTitle" :SpotEnTitle="SpotEnTitle"></Spot>
        <div class="Concept_one_img">
            <img :src="ConceptData.image_one" class="my_cover" alt=""/>
        </div>
        <div class="background_two_box">
            <div class="text_one">
                <div class="title_one">{{ ConceptData.title_one }}</div>
                <div class="info_one">{{ ConceptData.info_one }}</div>
            </div>
            <div class="text_two">
                <div class="left_tow_info">
                    <div class="title_two">{{ ConceptData.title_two }}</div>
                    <div class="info_two">{{ ConceptData.info_two }}</div>
                </div>
                <div class="right_img">
                    <img class="my_cover" :src="ConceptData.image_two" alt=""/>
                </div>
            </div>
        </div>
        <div class="soybean_box">
            <div class="text">大豆蛋白纤维床垫5G功能介绍</div>
        </div>
        <div class="soybean_content">
            <div class="five_conteir">
                <div class="five_G_item" v-for="(item, index) in five_G" :key="index">
                    <div class="g_img">
                        <img class="my_cover" :src="item.image" alt=""/>
                    </div>
                    <div class="g_name">{{ item.name }}</div>
                    <div class="g_info">{{ item.info }}</div>
                </div>
            </div>
            <Spot style="margin-top: 140px; margin-bottom: 116px" :SpotZhTitle="SpotZhTitle1" :SpotEnTitle="SpotEnTitle1"></Spot>
            <div class="honor_swiper_box">
                <div class="prev_box" slot="button-prev">
                    <img src="../../../assets/img/yellow_prev.png" class="my_cover" alt=""/>
                </div>
                <div class="center_swiper_box">
                    <swiper ref="mySwiper" style="width: 100%; height: 100%" :options="Optionshonor">
                        <swiper-slide class="border_box" v-for="(item, index) in ConceptData.honor" :key="index">
                            <img class="my_cover" :src="item" alt=""/>
                        </swiper-slide>
                    </swiper>
                </div>
                <div class="next_box" slot="button-prev">
                    <img src="../../../assets/img/yellow_next.png" class="my_cover" alt=""/>
                </div>
            </div>
        </div>
        <Spot style="margin-top: 120px; margin-bottom: 102px" :SpotZhTitle="SpotZhTitle2" :SpotEnTitle="SpotEnTitle2"></Spot>
        <div class="fiber_box">
            <img class="my_cover" src="../../../assets/img/735-2.png" alt=""/>
        </div>
    </div>
</template>

<script>
    import Spot from "../../../components/spot";
    import {getProductConceptOne} from "../../../http/api";

    export default {
        name: "",
        props: {},
        components: {
            Spot,
        },
        data() {
            return {
                ConceptData: {},

                Optionshonor: {
                    slidesPerView: 4,
                    spaceBetween: 16,
                    navigation: {
                        nextEl: ".next_box",
                        prevEl: ".prev_box",
                    },
                },
                SpotZhTitle: "产品理念",
                SpotEnTitle: "Product concept",
                SpotZhTitle1: "产品资质荣誉",
                SpotEnTitle1: "Product qualification and honor",
                SpotZhTitle2: "大豆纤维演变过程",
                SpotEnTitle2: "Evolution of soybean fiber",
                topscroll: 0,
                five_G: [
                    {
                        image: require("../../../assets/img/5G-02-01.png"),
                        name: "全芯透气",
                        info: "FULL CORE\nVENTILATION",
                    },
                    {
                        image: require("../../../assets/img/5G-02-02.png"),
                        name: "负氧离子",
                        info: "HIGH NEGATIVE\nOXYGEN ION",
                    },
                    {
                        image: require("../../../assets/img/5G-02-03.png"),
                        name: "亲肤舒适",
                        info: "SKIN FRIENDLY\nCOMFORT",
                    },
                    {
                        image: require("../../../assets/img/5G-02-04.png"),
                        name: "抗菌防螨",
                        info: "ANTIBACTERIAL AND\nMITE CONTROL",
                    },
                    {
                        image: require("../../../assets/img/5G-02-05.png"),
                        name: "健康环保",
                        info: "HEALTH AND\nENVIRONMENTAL PROTECTION",
                    },
                    {
                        image: require("../../../assets/img/5G-02-06.png"),
                        name: "不易变形",
                        info: "NOT EASY\nTO DEFORM",
                    },
                    {
                        image: require("../../../assets/img/5G-02-07.png"),
                        name: "无异味",
                        info: "NO ODOR",
                    },
                    {
                        image: require("../../../assets/img/5G-02-08.png"),
                        name: "可回收再利用",
                        info: "RECYCLABLE",
                    }
                ],
            };
        },
        methods: {
            scrollHandle(e) {
                let top = e.srcElement.scrollingElement.scrollTop; // 获取页面滚动高度
                this.topscroll = top;
            },
        },
        mounted() {
            this.$nextTick(function () {
                window.addEventListener("scroll", this.scrollHandle); //绑定页面滚动事件
            });
            getProductConceptOne().then((res) => {
                this.ConceptData = res.data;
            });
        },
        watch: {
            topscroll(val) {
                if (val > 978) {
                    $("#Weil .left_tow_info").addClass("text_rightA0a");
                    $("#Weil .right_img").addClass("image_rightA0ab");
                }
            },
        },
    };
</script>

<style scoped lang='scss'>
    #Weil {
        width: 100%;
        
        .Concept_one_img {
            width: 100%;
            // height: 502.96px;
            height: 26.13vw;
        }
        
        .background_two_box {
            width: 100%;
            height: 1026px;
            background: rgba(206, 223, 218, 0.2);
            
            .text_one {
                padding-top: 30px;
                width: 1200px;
                margin: 0 auto 70px auto;
                min-height: 180px;
                
                .title_one {
                    font-size: 22px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    line-height: 30px;
                    color: #666666;
                    text-align: center;
                }
                
                .info_one {
                    margin-top: 30px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    line-height: 30px;
                    color: #666666;
                }
            }
            
            .text_two {
                width: 100%;
                height: 746px;
                display: flex;
                
                .left_tow_info {
                    width: 531px;
                    height: 100%;
                    // margin-left: 360px;
                    margin-left: 18.75vw;
                    margin-right: 69px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    
                    .title_two {
                        font-size: 50px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #225928;
                        width: 407px;
                    }
                    
                    .info_two {
                        margin-top: 40px;
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        line-height: 30px;
                        color: #666666;
                    }
                }
                
                .right_img {
                    width: 50vw;
                    height: 100%;
                }
            }
        }
        
        .soybean_box {
            // width: 1200px;
            width: 62.5vw;
            height: 91px;
            background: #07241c;
            font-size: 30px;
            font-family: PingFang SC;
            font-weight: 500;
            line-height: 91px;
            color: #ffffff;
            margin: 100px auto 53px auto;
            
            .text {
                margin-left: 71px;
            }
        }
        
        .soybean_content {
            // width: 1390px;
            width: 72.39vw;
            background-image: url("../../../assets/img/soybean_back.png");
            background-size: 1200px 762px;
            background-position: center;
            background-repeat: no-repeat;
            margin: 0 auto;
            
            .five_conteir {
                // width: 1200px;
                width: 62.5vw;
                margin: 0 auto;
                height: 307px;
                padding-top: 88px;
                display: flex;
                justify-content: space-between;
                
                .five_G_item {
                    // width: 222px;
                    width: 7.2vw;
                    height: 100%;
                    background: #ffffff;
                    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                    transition: all 0.5s linear;
                    
                    .g_img {
                        width: 96px;
                        height: 96px;
                        margin: 31px auto 27px auto;
                    }
                    
                    .g_name {
                        font-size: 20px;
                        font-family: PingFang SC;
                        font-weight: 500;
                        line-height: 42px;
                        color: #a48448;
                        margin-bottom: 10px;
                        text-align: center;
                        transition: all 0.5s linear;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    
                    .g_info {
                        font-size: 10px;
                        font-family: PingFang SC;
                        font-weight: 500;
                        line-height: 22px;
                        color: #a48448;
                        /*color: #666666;*/
                        margin: 0 20px;
                        transition: all 0.5s linear;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        white-space: pre-wrap;
                        text-align: center;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }
                }
                
                .five_G_item:hover {
                    width: 9vw;
                    height: 100%;
                    background: #c8a86a;
                    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                    transition: all 0.5s linear;
                    cursor: pointer;
                    
                    .g_img {
                        width: 96px;
                        height: 96px;
                        margin: 31px auto 27px auto;
                        transition: all 0.5s linear;
                    }
                    
                    .g_name {
                        font-size: 26px;
                        font-family: PingFang SC;
                        font-weight: 500;
                        line-height: 42px;
                        color: #fff;
                        margin-bottom: 10px;
                        text-align: center;
                        transition: all 0.5s linear;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    
                    .g_info {
                        font-size: 16px;
                        font-family: PingFang SC;
                        font-weight: 500;
                        line-height: 22px;
                        color: #fff;
                        margin: 0 20px;
                        transition: all 0.5s linear;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }
                }
            }
            
            .honor_swiper_box {
                width: 100%;
                // height: 416px;
                height: 21.66vw;
                display: flex;
                justify-content: space-between;
                align-items: center;
                
                .prev_box {
                    width: 58px;
                    height: 52px;
                }
                
                .center_swiper_box {
                    // width: 1214px;
                    width: 63.22vw;
                    height: 100%;
                    
                    .border_box {
                        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                    }
                }
                
                .next_box {
                    width: 58px;
                    height: 52px;
                }
            }
        }
        
        .fiber_box {
            width: 100%;
            // height: 379px;
            height: 19.73vw;
            margin-bottom: 149px;
        }
    }
</style>
