<template>
  <div id="About">
    <!-- 关于八益 -->
    <div class="scend_menu">
      <div
        :class="scendActive === index ? 'center_menu1' : 'center_menu'"
        v-for="(item, index) in about"
        :key="index"
        @click="seclet(index)"
      >
        <div class="text_box">{{ item.name }}</div>
      </div>
    </div>
    <DevelopmentHistory v-if="scendActive === 0"></DevelopmentHistory>
    <SocialResponsibility v-if="scendActive === 1"></SocialResponsibility>
    <CooperativePartner v-if="scendActive === 2"></CooperativePartner>
<!--    <Case v-if="scendActive === 3 "></Case>-->
  </div>
</template>

<script>
import DevelopmentHistory from "./aboutChild/developmentHistory";
import SocialResponsibility from "./aboutChild/socialResponsibility";
import CooperativePartner from "./aboutChild/cooperativePartner";
import Case from "./aboutChild/case";
export default {
  name: "",
  props: {},
  components: {
    DevelopmentHistory,
    SocialResponsibility,
    CooperativePartner,
    Case,
  },
  data() {
    return {
      scendActive: 0,
      about: [
        {
          name: "发展历程",
        },
        {
          name: "社会责任",
        },
        {
          name: "合作伙伴",
        },
        // {
        //   name: "酒店/工程案例",
        // },
      ],
    };
  },
  methods: {
    seclet(index) {
      this.scendActive = index;
      localStorage.setItem("aboutActive", index);
    },
  },
  mounted() {
    this.scendActive = localStorage.getItem("aboutActive")
      ? Number(localStorage.getItem("aboutActive"))
      : 0;
  },
  destroyed(){
    localStorage.removeItem("aboutActive")
  },
};
</script>

<style scoped lang='scss'>
#About {
  width: 100%;
  .scend_menu {
    height: 133px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 10px;
    margin-left: -15px;
    .center_menu {
      height: 43px;
      border-radius: 30px;
      background-color: #fff;
      margin-left: 30px;
      cursor: pointer;
      .text_box {
        margin: 0 15px;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 43px;
        color: #666;
      }
    }
    .center_menu1 {
      height: 43px;
      border-radius: 30px;
      background-color: #225928;
      margin-left: 30px;
      cursor: pointer;
      .text_box {
        margin: 0 15px;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 43px;
        color: #c8a86a;
      }
    }
  }
}
</style>