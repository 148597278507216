<template>
  <div id="SleepResearch">
    <Header
      id="Header"
      class="BrandJoining_Header"
      :background="background"
      :style="position"
    ></Header>
    <div class="top_banner">
      <img :src="banner_img" class="my_cover" alt="" />
    </div>
    <Crumbs :marginleft="marginleft" :crumbs="crumbs" :crumbs_active="crumbs_active"></Crumbs>
    <Weil v-if="crumbs_active == 0"></Weil>
    <Mattress v-if="crumbs_active == 1"></Mattress>
    <Workshop v-if="crumbs_active == 2"></Workshop>
    <About v-if="crumbs_active == 3"></About>
  </div>
</template>

<script>
import Header from "../../components/Header";
import Crumbs from "../../components/crumbs";
import Weil from "./components/Weil";
import Mattress from "./components/Mattress";
import Workshop from "./components/Workshop";
import About from "./components/About";
export default {
  name: "",
  props: {},
  components: {
    Header,
    Crumbs,
    Weil,
    Mattress,
    Workshop,
    About,
  },
  data() {
    return {
      banner_img: "",
      crumbs_active: 0,
      marginleft:{
        "margin-right": "46px"
      },
      crumbs: [
        {
          name: "八益·维宜尔产品理念",
        },
        {
          name: "八益床垫产品理念",
        },
        {
          name: "生产车间",
        },
        {
          name: "关于八益",
        },
      ],
      background: {
        "background-color": "transparent",
      },
      position: {
        position: "absolute",
        top: "0",
      },
    };
  },
  mounted() {
    this.crumbs_active = localStorage.getItem("crumbs_active")
      ? localStorage.getItem("crumbs_active")
      : 0;
    var banner = JSON.parse(localStorage.getItem("getBanner"));
    this.banner_img = banner[1].image;
  },
  watch: {
    crumbs_active(val) {
      localStorage.setItem("crumbs_active", val);
    },
  },
  destroyed() {
    localStorage.removeItem("crumbs_active");
  },
};
</script>

<style scoped lang='scss'>
#SleepResearch {
  width: 100%;
  .BrandJoining_Header {
    width: 100%;
  }
  .top_banner {
    height: 100%;
    // height: 850px;
    height: 44.27vw;
  }
}
</style>