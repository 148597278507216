<template>
  <div class="history">
    <swiper
      id="history"
      class="swiper"
      :options="HistoryswiperOption"
      v-if="history.length > 0"
    >
      <swiper-slide
        class="one"
        v-if="index == 0"
        v-for="(item, index) in history"
        :key="index"
      >
        <div
          class="background trans"
          :style="{ background: 'url(' + item.image + ')' }"
        ></div>
        <div class="content trans" style="opacity: 1">
          <span class="brief fz24 block SOURCEHANSANSCN-MEDIUM">{{
            item.info
          }}</span>
          <span class="time fz70 block DEMOS-LT-MEDIUM-ITALIC"
            >{{ item.year }}年</span
          >
          <span class="text inline-block trans"></span>
        </div>
        <i class="line inline-block" style="width: 6466px"></i>
      </swiper-slide>
      <swiper-slide
        class="two"
        v-if="index != 0"
        v-for="(item, index) in history"
        :key="index"
      >
        <div class="slide" :class="{ active_oli_slide: index % 2 != 0 }">
          <div class="brief trans fz16 SOURCEHANSANSCN-MEDIUM">
            {{ item.info }}
          </div>
          <div class="title trans fz50 DEMOS-LT-MEDIUM-ITALIC" style="font-size:27px;">
            {{ item.year }}年
          </div>
          <div class="circle trans inline-block"><i></i></div>
        </div>
        <div class="slide to" :class="{ active_oli_slide: index % 2 == 0 }">
          <div class="title trans fz50 DEMOS-LT-MEDIUM-ITALIC" style="font-size:27px;">
            {{ item.year }}年
          </div>
          <div class="brief trans fz16 SOURCEHANSANSCN-MEDIUM">
            {{ item.info }}
          </div>
          <div class="circle trans inline-block"><i></i></div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="history_prev" slot="button-prev">
      <img
        src="../../../../assets/img/history_prev.png"
        class="my_cover"
        alt=""
      />
    </div>
    <div class="history_next" slot="button-next">
      <img
        src="../../../../assets/img/history_next.png"
        class="my_cover"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import { getCourse } from "../../../../http/api";

export default {
  name: "EnterpriseHistory",
  components: {},
  data() {
    return {
      history: [],
      HistoryswiperOption: {
        slidesPerView: 4,
        spaceBetween: 43,
        freeModeMomentumRatio: 5,
        freeModeSticky: false,
        // loop: true,
        freeMode: true,
        freeModeMomentum: true,
        navigation: {
          nextEl: ".history_next",
          prevEl: ".history_prev",
        },
      },
    };
  },
  created() {
    this.GetNewsInfo();
  },
  methods: {
    GetNewsInfo() {
      getCourse().then((res) => {
        this.history = res.data;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.history {
  width: 100%;
  // height: 35.93vw;
  height: 690px;
  margin-bottom: 121px;
  position: relative;
  .history_prev {
    width: 48px;
    height: 48px;
    position: absolute;
    left: 70px;
    top: 44.9%;
    z-index: 999;
  }
  .history_next {
    width: 48px;
    height: 48px;
    position: absolute;
    right: 100px;
    z-index: 9999;
    top: 44.9%;
  }
  .history_prev:focus,
  .history_next:focus {
    outline: none;
  }
}

#history {
  border-top: 1px solid #e5e5e5;
  background-size: cover !important;
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  height: 100%;
  .swiper-wrapper .one {
    width: 50% !important;
    height: 619px;
    // height: 32.23vw;
    text-align: right;
    position: relative;
    z-index: 2;
    padding-top: 71px;

    .background {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
      margin: auto;
      background-repeat: no-repeat !important;
      background-size: 100% 100% !important;
    }

    .content {
      width: 50vw;
      position: relative;
      z-index: 2;
      text-align: left;
      // margin-left: 361px;
      margin-left: 18.88vw;
      margin-top: 84px;

      .block {
        display: block;
      }

      .inline-block {
        display: inline-block;
      }

      .brief {
        font-size: 50px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
        margin-bottom: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .time {
        font-size: 46px;
        font-family: PingFang SC;
        font-weight: 800;
        color: #ffffff;
      }

      .text {
        width: 38px;
        height: 38px;
        border: 2px solid #ffffff;
        border-radius: 50%;
        position: relative;
        margin-top: 14px;
      }

      .text::before {
        width: 20px;
        height: 20px;
        background: #ffffff;
        border-radius: 50%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
        margin: auto;
        content: "";
        transition: all 0.8s ease 0s;
      }
    }

    .line {
      display: inline-block;
      height: 0;
      border-top: 1px solid #c2c2c2;
      position: absolute;
      left: 42%;
      right: 50%;
      top: 333px;
      // top: 17.33vw;
      bottom: auto;
      z-index: 3;
      margin-right: 17px;
      transition: all 6000ms ease 0s;
    }
  }

  .swiper-wrapper .two {
    // width: 270px !important;
    width: 14.06vw !important;
    position: relative;
    z-index: 3;
    //padding-top: 60px;

    .slide {
      // min-height: 266px;
      min-height: 266px;
      width: 100%;
      position: relative;
      //margin-left: 60px;
      opacity: 0;

      .brief {
        // width: 100%;
        width: 80%;
        min-height: 53px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
        margin: 16px 0 6px;
        position: absolute;
        bottom: 52px;
        font-size: 22px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 33px;
        color: #666666;
      }

      .title {
        width: 100%;
        color: #005a3f;
        font-size: 40px;
        line-height: 60px;
        position: absolute;
        bottom: -11px;
      }

      .circle {
        width: 20px;
        height: 20px;
        border: 2px solid #005a3f;
        border-radius: 50%;
        position: absolute;
        left: 0;
        bottom: -79px;
        z-index: 1;

        i {
          width: 14px;
          height: 14px;
          display: block;
          background: #005a3f;
          border-radius: 50%;
          position: relative;
          margin: 3px auto;
        }

        i::before {
          height: 30px;
          display: block;
          border-right: 1px solid #005a3f;
          position: absolute;
          bottom: 18px;
          right: 50%;
          z-index: 2;
          content: "";
          animation: line 1s linear;
          -webkit-animation: line 1s linear;
        }
      }
    }

    .active_oli_slide {
      opacity: 1;
    }

    .to {
      margin-left: 0;
      margin-top: 91px;

      .title {
        padding-left: 0;
        top: 22px;
      }

      .brief {
        margin: 0px 0 16px;
        top: 90px;
        padding-left: 0;
        bottom: 60px;
      }

      .circle {
        left: 0;
        right: 140px;
        top: -37px;
        bottom: auto;

        i::before {
          width: 90px;
          bottom: auto;
          top: 18px;
        }
      }
    }
  }
}

.swiper_ip {
  display: none;
}
</style>
