<template>
  <div id="cooperativePartner">
    <!-- 合作伙伴 -->
    <div class="partner_box">
      <div class="item_box" v-for="(item, index) in partnerData" :key="index">
        <img class="my_cover" :src="item.image" alt="" />
      </div>
    </div>
    <div class="more" @click="mored" v-if="moreFlag">+点击加载全部</div>
  </div>
</template>

<script>
import { getPartnerList } from "../../../../http/api";
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      limit: 12,
      partnerData: [],
      moreFlag: true,
    };
  },
  methods: {
    mored() {
      this.limit = 9999;
      this.getdata(this.limit);
      this.moreFlag = false;
    },
    getdata(limit) {
      getPartnerList({
        page: 1,
        limit: limit,
      }).then((res) => {
        this.partnerData = res.data.data;
        console.log(res);
      });
    },
  },
  mounted() {
    this.getdata(this.limit);
  },
  watch: {},
  computed: {},
  filters: {},
};
</script>

<style scoped lang='scss'>
#cooperativePartner {
  width: 100%;
  .partner_box {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    
    .item_box:nth-child(4n) {
      margin-right: 0;
    }
    .item_box {
      width: 278px;
      height: 135px;
      border: 1px solid #9A9A9A;
      margin-right: 20px;
      margin-bottom: 30px;
    }
  }
  .more {
    width: 100%;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    text-align: center;
    margin-bottom: 121px;
  }
}
</style>