<template>
  <div id="Mattress">
    <!-- 八益床垫产品理念 -->
    <Spot
      style="margin-top: 112px; margin-bottom: 88px"
      :SpotZhTitle="SpotZhTitle"
      :SpotEnTitle="SpotEnTitle"
    ></Spot>
    <div class="Mattress_firs_box">
      <div class="title_box">
        <div class="zh">{{ ConceptTwoData.title }}</div>
        <div class="en">{{ ConceptTwoData.en }}</div>
        <div class="dian"></div>
      </div>
      <div class="info_box">{{ ConceptTwoData.info }}</div>
    </div>
    <Spot
      style="margin-top: 120px; margin-bottom: 89px"
      :SpotZhTitle="SpotZhTitle1"
      :SpotEnTitle="SpotEnTitle1"
    ></Spot>
    <div class="advantages_box">
      <div
        class="advantages_item"
        v-for="(item, index) in ConceptTwoData.advantages"
        :key="index"
      >
        <div class="img_left_box" v-if="index % 2 === 0">
          <div class="left_imgm" :class="'left_imgM' + index">
            <img :src="item.image" class="my_cover" alt="" />
          </div>
          <div class="right_text" :class="'right_textM' + index">
            <div class="title">{{ item.title }}</div>
            <div class="subTitle" v-if="item.subTitle">{{ item.subTitle }}</div>
            <div class="info">{{ item.info }}</div>
          </div>
        </div>
        <div class="img_right_box" v-else>
          <div class="right_text" :class="'right_textM' + index">
            <div class="title">{{ item.title }}</div>
            <div class="subTitle" v-if="item.subTitle">{{ item.subTitle }}</div>
            <div class="info">{{ item.info }}</div>
          </div>
          <div class="left_imgm" :class="'left_imgM' + index">
            <img :src="item.image" class="my_cover" alt="" />
          </div>
        </div>
      </div>
    </div>
    <Spot
      style="margin-top: 140px; margin-bottom: 74px"
      :SpotZhTitle="SpotZhTitle2"
      :SpotEnTitle="SpotEnTitle2"
    ></Spot>
    <div class="honor_swiper_box">
      <div class="prev_box" slot="button-prev">
        <img
          src="../../../assets/img/yellow_prev.png"
          class="my_cover"
          alt=""
        />
      </div>
      <div class="center_swiper_box">
        <swiper
          ref="mySwiper"
          style="width: 100%; height: 100%"
          :options="Optionshonor"
        >
          <swiper-slide
            class="border_box"
            v-for="(item, index) in ConceptTwoData.honor"
            :key="index"
          >
            <img class="my_cover" :src="item" alt="" />
          </swiper-slide>
        </swiper>
      </div>
      <div class="next_box" slot="button-prev">
        <img
          src="../../../assets/img/yellow_next.png"
          class="my_cover"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import Spot from "../../../components/spot";
import { getProductConceptTwo } from "../../../http/api";
export default {
  name: "",
  props: {},
  components: { Spot },
  data() {
    return {
      SpotZhTitle: "产品理念",
      SpotEnTitle: "Product concept",
      SpotZhTitle1: "产品优势",
      SpotEnTitle1: "Product advantages",
      SpotZhTitle2: "产品资质荣誉",
      SpotEnTitle2: "Product qualification and honor",
      ConceptTwoData: {},
      Optionshonor: {
        slidesPerView: 3,
        spaceBetween: 26,
        navigation: {
          nextEl: ".next_box",
          prevEl: ".prev_box",
        },
      },
      topscroll: 0,
    };
  },
  methods: {
    scrollHandle(e) {
      let top = e.srcElement.scrollingElement.scrollTop; // 获取页面滚动高度
      this.topscroll = top;
    },
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener("scroll", this.scrollHandle); //绑定页面滚动事件
    });
    getProductConceptTwo().then((res) => {
      this.ConceptTwoData = res.data;
    });
  },
  watch: {
    topscroll(val) {
      if (val > 233) {
        $("#Mattress .Mattress_firs_box .title_box").addClass("six_item1a");
        $("#Mattress .Mattress_firs_box .info_box").addClass("six_item1a");
      }
      if (val > 1090) {
        $("#Mattress .advantages_box .right_textM0").addClass("image_rightA0a");
        $("#Mattress .advantages_box .left_imgM0").addClass("text_rightA0a");
      }
      if (val > 1723) {
        $("#Mattress .advantages_box .left_imgM1").addClass("image_rightA0a");
        $("#Mattress .advantages_box .right_textM1").addClass("text_rightA0a");
      }
      if (val > 2362) {
        $("#Mattress .advantages_box .right_textM2").addClass("image_rightA0a");
        $("#Mattress .advantages_box .left_imgM2").addClass("text_rightA0a");
      }
    },
  },
};
</script>

<style scoped lang='scss'>
#Mattress {
  width: 100%;
  background-image: url("../../../assets/img/retailstore_back.png");
  background-size: 100% 944px;
  background-position-y: 3183px;
  background-repeat: no-repeat;
  padding-bottom: 217px;

  .Mattress_firs_box {
    width: 1200px;
    margin: 0 auto;
    height: 584px;
    background-image: url("../../../assets/img/Mattress_first_back.png");
    position: relative;
    .title_box {
      position: absolute;
      top: 76px;
      left: 71px;
      width: 314.33px;
      height: 100.17px;
      border-bottom: 1px solid #ffffff;
      border-left: 1px solid #ffffff;
      .zh {
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 37px;
        color: #d8c9af;
        margin: 8px 0 10px 25px;
      }
      .en {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 31px;
        color: #d8c9af;
        margin-left: 25px;
      }
      .dian {
        position: absolute;
        top: -7.5px;
        left: -7.5px;
        width: 15px;
        height: 15px;
        background: #c8a86a;
      }
    }
    .info_box {
      width: 629px;
      padding-top: 112px;
      margin: 0 111px 88px 461px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 40px;
      color: #ffffff;
      white-space: pre-wrap;
    }
  }
  .advantages_box {
    width: 100%;
    .advantages_item {
      width: 100%;
      height: 632px;
      .img_left_box {
        width: 100%;
        display: flex;
        height: 100%;
        .left_imgm {
          width: 50vw;
          height: 100%;
        }
        .right_textM2 {
          .title {
            margin: 189px 0 22px 67px !important;
          }
          .info {
            margin-left: 67px !important;
            margin-top: 60px !important;
          }
        }
        .right_text {
          width: 50vw;
          background: rgba(206, 223, 218, 0.2);
          .title {
            font-size: 50px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            line-height: 34px;
            color: #666666;
            margin: 138px 0 22px 111px;
          }
          .subTitle {
            font-size: 28px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 34px;
            color: #c8a86a;
            margin: 0 0 34px 111px;
          }
          .info {
            width: 515px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 34px;
            color: #666666;
            margin-left: 111px;
            white-space: pre-wrap;
          }
        }
      }
      .img_right_box {
        width: 100%;
        display: flex;
        height: 100%;
        .left_imgm {
          width: 50vw;
          height: 100%;
        }
        .right_textM1 {
          .title {
            margin-top: 48px !important;
          }
        }
        .right_text {
          width: 50vw;
          background: rgba(206, 223, 218, 0.2);
          .title {
            font-size: 50px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            line-height: 34px;
            color: #666666;
            margin: 148px 0 22px 133px;
          }
          .subTitle {
            font-size: 28px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 34px;
            color: #c8a86a;
            margin: 0 0 34px 133px;
          }
          .info {
            // width: 531px;
            width: 27.65vw;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 34px;
            color: #666666;
            margin-left: 133px;
            white-space: pre-wrap;
          }
        }
      }
    }
  }
  .honor_swiper_box {
    // width: 1380px;
    width: 71.875vw;
    margin: 0 auto;
    // height: 510px;
    height: 26.56vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .prev_box {
      width: 58px;
      height: 52px;
    }
    .center_swiper_box {
      // width: 1147px;
      width: 59.73vw;
      height: 100%;
      .border_box {
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      }
    }
    .next_box {
      width: 58px;
      height: 52px;
    }
  }
}
</style>