<template>
  <div id="socialResponsibility">
    <!-- 社会责任 -->
    <Spot
      style="margin-top: 0px; margin-bottom: 50px"
      :SpotZhTitle="SpotZhTitle"
      :SpotEnTitle="SpotEnTitle"
    ></Spot>
    <div class="info">{{ ponsibility.info }}</div>
    <div class="image">
      <img class="my_cover" :src="ponsibility.image" alt="" />
    </div>
    <Spot
      style="margin-top: 60px; margin-bottom: 75px"
      :SpotZhTitle="SpotZhTitle1"
      :SpotEnTitle="SpotEnTitle1"
    ></Spot>
    <div class="culture_box">
      <div
        class="culture_item"
        v-for="(item, index) in ponsibility.culture"
        :key="index"
      >
        <div class="icon">
          <div :class="'icon_5' + index"></div>
          <img :src="item.icon" alt="" />
        </div>
        <div class="title">{{ item.title }}</div>
        <div class="info">{{ item.info }}</div>
      </div>
    </div>
    <div class="footer_image">
      <img class="my_cover" :src="ponsibility.footer_image" alt="" />
    </div>
  </div>
</template>

<script>
import { getResponsibility } from "../../../../http/api";
import Spot from "../../../../components/spot";
export default {
  name: "",
  props: {},
  components: {
    Spot,
  },
  data() {
    return {
      ponsibility: {},
      SpotZhTitle: "集团简介",
      SpotEnTitle: "Group profile",
      SpotZhTitle1: "集团文化",
      topscroll:0,
      SpotEnTitle1: "Group culture",
      //   icon: [
      //     { image: require("../../../../assets/img/profile1.png") },
      //     { image: require("../../../../assets/img/profile2.png") },
      //     { image: require("../../../../assets/img/profile3.png") },
      //   ],
    };
  },
  methods: {
     scrollHandle(e) {
      let top = e.srcElement.scrollingElement.scrollTop; // 获取页面滚动高度
      this.topscroll = top;
    },
  },
  mounted() {
     this.$nextTick(function () {
      window.addEventListener("scroll", this.scrollHandle); //绑定页面滚动事件
    });
    getResponsibility().then((res) => {
      this.ponsibility = res.data;
    });
  },
  watch: {
    topscroll(val) {
      if(val > 1400){
        $('#socialResponsibility .culture_box').addClass('six_item1a')
      }
    },
  },
  computed: {},
  filters: {},
};
</script>

<style scoped lang='scss'>
#socialResponsibility {
  width: 100%;
  .info {
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 50px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 36px;
    color: #333333;
    white-space: pre-wrap;
    text-align: center;
  }
  .image {
    width: 100%;
    height: 616px;
  }
  .culture_box {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    .culture_item:hover {
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.14);
      transition: all 0.5s all;
    }
    .culture_item {
      width: 362px;
      transition: all 0.5s all;
      height: 398px;
      border: 1px solid #d1d1d1;
      .icon {
        width: 72.34px;
        height: 72.34px;
        border: 1px solid #666666;
        border-radius: 50%;
        margin: 50px auto 26px auto;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon_50 {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          border-left: 2px solid #04523a;
          border-top: 2px solid #04523a;
          border-bottom: 2px solid transparent;
          border-right: 2px solid transparent;
          border-radius: 50%;
          animation: border_5 infinite linear 2s;
        }
        .icon_51 {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          border-top: 2px solid #04523a;
          border-right: 2px solid #04523a;
          border-bottom: 2px solid transparent;
          border-left: 2px solid transparent;
          border-radius: 50%;
          animation: border_5 infinite linear 2s;
        }
        .icon_52 {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          border-bottom: 2px solid #04523a;
          border-right: 2px solid #04523a;
          border-top: 2px solid transparent;
          border-left: 2px solid transparent;
          border-radius: 50%;
          animation: border_5 infinite linear 2s;
        }
        @keyframes border_5 {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(365deg);
          }
        }
        img {
          width: 40.81px;
          height: 35.14px;
          display: block;
        }
      }
      .title {
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 28px;
        color: #333333;
        text-align: center;
      }
      .info {
        width: 326px;
        margin: 0 auto;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 30px;
        color: #666666;
        white-space: pre-wrap;
        margin-top: 30px;
      }
    }
  }
  .footer_image {
    width: 1200px;
    height: 592.5px;
    margin: 0 auto;
    margin-top: 79px;
    margin-bottom: 160px;
  }
}
</style>