<template>
  <div id="Workshop">
    <!-- 生产车间 -->
    <Spot
      style="margin-top: 112px; margin-bottom: 50px"
      :SpotZhTitle="SpotZhTitle"
      :SpotEnTitle="SpotEnTitle"
    ></Spot>
    <div class="six_img_box">
      <div class="left_box">
        <div class="left_one">
          <img :src="WorkShopData.one" class="my_cover" alt="" />
        </div>
        <div class="left_two">
          <img :src="WorkShopData.two" class="my_cover" alt="" />
        </div>
      </div>
      <div class="right_box">
        <div class="right_one">
          <img :src="WorkShopData.three" class="my_cover" alt="" />
        </div>
        <div class="right_two">
          <img :src="WorkShopData.four" class="my_cover" alt="" />
        </div>
        <div class="right_three">
          <div class="right_three_left">
            <img :src="WorkShopData.five" class="my_cover" alt="" />
          </div>
          <div class="right_three_right">
            <img :src="WorkShopData.six" class="my_cover" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getWorkShop } from "../../../http/api";
import Spot from "../../../components/spot";
export default {
  name: "",
  props: {},
  components: {
    Spot,
  },
  data() {
    return {
      SpotZhTitle: "生产车间",
      SpotEnTitle: "Production workshop",
      WorkShopData: {},
      topscroll: 0,
    };
  },
  methods: {
    scrollHandle(e) {
      let top = e.srcElement.scrollingElement.scrollTop; // 获取页面滚动高度
      this.topscroll = top;
    },
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener("scroll", this.scrollHandle); //绑定页面滚动事件
    });
    getWorkShop().then((res) => {
      console.log(res);
      this.WorkShopData = res.data;
    });
  },
  watch: {
    topscroll(val) {
      if (val > 200) {
        $(".six_img_box img").addClass("six_item1a");
      }
    },
  },
};
</script>

<style scoped lang='scss'>
#Workshop {
  width: 100%;
  background-image: url("../../../assets/img/retailstore_back.png");
  background-size: 100% 1137px;
  background-position-y: 460px;
  background-repeat: no-repeat;
  padding-bottom: 122px;

  .six_img_box {
    height: 1062px;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    .left_box {
      width: 396px;
      height: 100%;
      .left_one {
        width: 100%;
        height: 603px;
        overflow: hidden;
      }
      img {
        transition: all 0.3s;
      }
      .left_one:hover img,
      .left_two:hover img {
        transform: scale(1.2);
        transition: all 0.3s;
      }
      .left_two {
        width: 100%;
        height: 451px;
        margin-top: 8px;
        overflow: hidden;
        img {
          transition: all 0.3s;
        }
      }
    }
    .right_box {
      width: 800px;
      margin-left: 6px;
      height: 100%;
      .right_one {
        width: 100%;
        height: 302px;
        overflow: hidden;
        img {
          transition: all 0.3s;
        }
      }

      .right_one:hover img,
      .right_two:hover img,
      .right_three_left:hover img,
      .right_three_right:hover img {
        transform: scale(1.2);
        transition: all 0.3s;
      }
      .right_two {
        width: 100%;
        height: 394px;
        margin: 8px 0;
        overflow: hidden;
        img {
          transition: all 0.3s;
        }
      }
      .right_three {
        width: 100%;
        height: 350px;
        display: flex;
        .right_three_left {
          width: 400px;
          height: 100%;
          overflow: hidden;
          img {
            transition: all 0.3s;
          }
        }
        .right_three_right {
          width: 398px;
          height: 100%;
          margin-left: 2px;
          overflow: hidden;
          img {
            transition: all 0.3s;
          }
        }
      }
    }
  }
}
</style>