<template>
  <div id="case">
    <!-- 酒店/工程案例 -->
    <div class="caseList_box" v-if="detailFlag == 0">
      <div
        class="list_item"
        v-for="(item, index) in caseList"
        :key="index"
        @click="toDetail(item)"
        style="cursor: pointer"
      >
        <div class="left_imgs">
          <img class="my_cover" :src="item.cover" alt="" />
        </div>
        <div class="right_text">
          <div class="timer">{{ item.time.substring(0, 10) }}</div>
          <div class="title">{{ item.title }}</div>
          <div class="info">{{ item.info }}</div>
          <div class="lookMore">
            查看更多
            <div class="lookbox">></div>
          </div>
        </div>
      </div>
      <div class="pagegation">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          layout="prev, pager, next"
           :current-page.sync="page"
          :page-size="limit"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <div class="detail_box" v-else>
      <div class="position_box">
        <div class="">
          当前位置：<span class="home" @click="tohome">首页</span> >
          <span class="about" @click="toabout">关于八益</span> >
          <span class="case" @click="tolist">酒店/工程案例</span> ><span
            class="detailTitle"
            >{{ detailData.title }}</span
          >
        </div>
      </div>
      <div class="title_back_box">
        <div class="left_box">
          <div class="title">{{ detailData.title }}</div>
          <div class="date_look">
            <span class="date"></span>发布日期：{{ detailData.time
            }}<span class="look">浏览次数：{{ detailData.visit }}</span>
          </div>
        </div>
        <div class="right_box" @click="tolist" style="cursor: pointer">
          返回列表
        </div>
      </div>
      <div class="line_"></div>
      <div class="v_htlm" v-html="detailData.text"></div>
      <div class="line_1"></div>
      <div class="prev_next">
        <div class="prev" @click="prev" v-if="detailData.prev">
          <div>上一条：{{ detailData.prev.title }}</div>
          <div class="min_line"></div>
        </div>
        <div class="next" @click="next" v-if="detailData.next">
          <div>下一条：{{ detailData.next.title }}</div>
          <div class="min_line"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCaseList, getCaseInfo } from "../../../../http/api";
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      caseList: [],
      data: {},
      detailFlag: 0,
      detailsID: "",
      detailData: {},
      total: 0,
      limit:5,
      page:1,
    };
  },
  methods: {
    handleCurrentChange(page) {
      this.get(this.limit,page)
    },
    prev() {
      this.getdetail(this.detailData.prev.id);
    },
    next() {
      this.getdetail(this.detailData.next.id);
    },
    tohome() {
      this.$router.push("/");
    },
    toabout() {
      this.$router.push({
        Path: "/sleepResearch",
        query: {
          active: 3,
        },
      });
    },
    tolist() {
      this.detailFlag = 0;
      localStorage.setItem("details", this.detailFlag);
    },
    getdetail(id) {
      getCaseInfo({ id }).then((res) => {
        console.log(res);
        res.data.text = res.data.text.replace(
          /<img/g,
          '<img style="max-width:100%;"'
        );
        this.detailData = res.data;
      });
    },
    toDetail(item) {
      this.getdetail(item.id);
      this.detailFlag = 1;
      localStorage.setItem("details", this.detailFlag);
      localStorage.setItem("detailsID", item.id);
    },
    get(limit,page) {
      getCaseList({
        limit: limit,
        page:page
      }).then((res) => {
        this.caseList = res.data.data;
        this.data = res.data;
        this.total = res.data.total;
      });
    },
  },
  mounted() {
    this.get(this.limit,this.page)
    $(".pagegation .el-pagination i").remove();
    $(
      ".pagegation .el-pagination .btn-prev,.pagegation .el-pagination .btn-next"
    ).css({
      width: "72px",
      height: "34px",
      background: "#F4F4F4",
    });
    $(".pagegation .el-pagination .btn-prev").text("上一页");
    $(".pagegation .el-pagination .btn-next").text("下一页");

    if (localStorage.getItem("details") && localStorage.getItem("detailsID")) {
      this.detailFlag = localStorage.getItem("details");
      this.detailsID = localStorage.getItem("detailsID");
      this.getdetail(this.detailsID);
    }
  },
  destroyed() {
    localStorage.removeItem("details");
  },
};
</script>

<style scoped lang='scss'>
#case {
  width: 100%;
  .caseList_box {
    width: 1300px;
    margin: 0 auto;
    margin-top: -30px;
    .list_item {
      width: 100%;
      transition: 0.5s linear all;
      border-bottom: 1px solid #f3f3f3;
      display: flex;
      justify-content: space-between;
      // margin-bottom: 40px;
      .left_imgs {
        width: 370px;
        height: 276px;
        margin: 40px 0 40px 0;
        margin-left: 50px;
      }
      .right_text {
        margin-top: 70px;
        margin-right: 50px;
        width: 745px;
        height: 100px;
        .timer {
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: #b3b3b3;
        }
        .title {
          font-size: 14px;
          font-family: MicrosoftYaHei;
          margin: 40px 0;
          color: #000000;
        }
        .info {
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #888888;
          line-height: 40px;
        }
        .lookMore {
          display: flex;
          margin-top: 41px;
          color: #999999;
          transition: 0.5s linear all;
          .lookbox {
            width: 14px;
            transition: 0.5s linear all;
            height: 14px;
            border-radius: 50%;
            background: #999999;
            font-size: 14px;
            line-height: 12px;
            text-align: center;
            margin-left: 15px;
            color: #fff;
          }
        }
      }
    }
    .list_item:hover {
      background: #f5f5f5;
      transition: 0.5s linear all;
      .lookMore {
        color: #225928;
        transition: 0.5s linear all;
        .lookbox {
          background: #225928;
          transition: 0.5s linear all;
        }
      }
    }
    .pagegation {
      width: 1200px;
      height: 34px;
      margin-bottom: 140px;
      display: flex;
      justify-content: center;
    }
  }
  .detail_box {
    width: 1200px;
    margin: 0 auto;
    .position_box {
      width: 100%;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      line-height: 28px;
      color: #999999;
      cursor: pointer;

      .home {
      }
      .about {
      }
      .case {
      }
      .detailTitle {
        color: #000000;
      }
    }
    .title_back_box {
      width: 100%;
      height: 67px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 60px;
      .left_box {
        //   <div class="title">{{detailData.title}}</div>
        //   <div class="date_look">
        //     <span class="date"></span>发布日期：{{detailData.time}}<span class="look">浏览次数：{{detailData.visit}}</span>
        //   </div>
        .title {
          font-size: 22px;
          font-family: MicrosoftYaHei;
          line-height: 40px;
          color: #333333;
        }
        .date_look {
          font-size: 16px;
          font-family: MicrosoftYaHei;
          line-height: 40px;
          color: #999999;
          display: flex;
          .date {
            display: block;
          }
          .look {
            display: block;
            margin-left: 20px;
          }
        }
      }
      .right_box {
        width: 120px;
        height: 40px;
        background: #F5F5F5;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        line-height: 40px;
        text-align: center;
        color: #999999;
        transition: all 0.3s linear;
      }
      .right_box:hover{
        background: #225928;
        transition: all 0.3s linear;
        color: #C8A86A;
      }
    }
    .line_ {
      width: 100%;
      height: 1px;
      background: #e7e7e7;
      margin: 30px 0 35px 0;
    }
    .line_1 {
      width: 100%;
      height: 1px;
      background: #e7e7e7;
      margin: 30px 0 35px 0;
    }
    .prev_next {
      width: 100%;
      height: 30px;
      position: relative;
      margin-bottom: 122px;
      .prev {
        position: absolute;
        left: 0;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        line-height: 30px;
        color: #000000;
        cursor: pointer;
        .min_line {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 2px;
          background: #000000;
        }
      }
      .next {
        position: absolute;
        right: 0;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        line-height: 30px;
        color: #000000;
        cursor: pointer;
        .min_line {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 2px;
          background: #000000;
        }
      }
    }
  }
}
</style>